body#login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  * {
    box-sizing: border-box;
  }

  #paper-container {
    background-image: url('../img/paper-texture.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 50px;
    color: #432424;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 450px;
    max-width: 95vw;
    position: relative;
    z-index: 10;

    p {
      font-family: Alike Angular;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: #432424;
      margin: 10px 0;

      &.error {
        color: #a70000;
        display: none;

        &.error-active {
          display: block;
        }
      }

      a {
        color: #000;
      }
    }

    #logo {
      width: 100%;
      min-height: 30px;
      flex: 0 1 300px;
      position: relative;
      margin-bottom: -50px;
      margin-top: -40px;

      img {
        position: absolute;
        top: -30px;
        left: -50px;
        width: calc(100% + 100px);
        height: calc(100% + 60px);
        object-fit: contain;
        pointer-events: none;
      }
    }

    form {
      width: 300px;
      max-width: 100%;
      margin: 20px auto 0;

      label {
        display: block;
        text-align: left;
        font-family: Alike Angular;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 3px;
      }

      input {
        display: block;
        width: 100%;
        padding: 10px;
        border: 3px solid #432424;
      }

      button {
        border: 3px solid #432424;
        padding: 10px 30px;
        background: transparent;
        text-transform: uppercase;
        font-family: Alike Angular;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 24px;
        margin-top: 20px;
        display: block;
        width: 100%;
        cursor: pointer;
        transition: all 0.4s ease-in-out;

        &:hover {
          background: #432424;
          color: #fff;
        }
      }
    }
  }
}

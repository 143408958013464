.scroll-container.active {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:2;
  background-image: radial-gradient(63.62% 74.88% at 50% 50%, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.9) 100%);
  z-index: -100;

  &:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.visible {
    z-index: 1000;

    > * {
      opacity: 0;
    }
  }

  &.clickable {
    opacity: 1;
  }

  .scroll {
    background-image: url("../img/paper-texture.webp");
    max-width: 100%;
    padding: 80px;
    box-sizing: border-box;
    width: 1000px;
    z-index: 5;
    position: absolute;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 784px;
    max-height: 90vh;
    // transform: rotate(90deg);

    @media(max-width:1100px) {
      min-height: 70%;
      max-width: 1000px;
      width: 100%;
      background-size: 102% 102%
    }

    @media (max-height: 800px) {
      padding: 50px 50px;
    }

    @media (max-width: 690px) {
      padding: 42px 42px;
      background-size: 106% 106%;
      background-image: url("../img/paper-texture-mobile.webp");
      height: 100%;
    }

    .content {
      color: #432424;
      position: relative;
      height: 100%;
      // overflow-x: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-height: 800px) {
        justify-content: unset;
        overflow-y: auto;
        //padding: 50px 50px;
      }

      //phones
      @media (max-width: 690px) {
        display: block;
        overflow-y: auto;
        max-height: 100%;
        padding-right: 0px;
        justify-content: unset;
      }

      p {
        font-size: 16px;
        @media(max-width: 1100px) {
          font-size: 15px;
        }
      }

      h1 {
        font-size: 32px;
        @media(max-width: 1100px) {
          font-size: 26px;
        }
      }

      .close-help-button {
        position: absolute;
        right: 2%;
        top: 2%;

        @media(max-width:1100px) {
          right: 1%;
          top: 5%;
        }

        //tall phones
        @media (max-width: 690px) {
          top: 0%;
        }

        img {
          width: 30px;
          height: 30px;
          transition: all 0.2s ease-in-out;
          opacity: 1;

          &:hover {
            opacity: .5;
            width: 32px;
            height: 32px;
            cursor: pointer;
            z-index: 1;
          }

        }
      }

      .instructions {
        h1 {
          margin-bottom: .25em;
        }
        p:first-of-type {
          margin-top: .5em;
        }
      }

      .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: -5%;
        margin-left: -3%;
        img {
          width: 400px;
          height: 224px;

          @media(max-width: 1100px) {
            width: 300px;
            height: 168px;
          }

          @media(max-width: 700px) {
            width: 269px;
            height: 151px;
          }


        }

        @media (max-width: 690px) {
          align-items: center;
          margin-right: 0;
          margin-left: 0;
          margin-bottom: -20px;
        }
      }

      .intro {
        display: flex;
        flex-direction: row;
        margin-top: -23px;

        @media (max-height: 800px) {
          margin-top: 0;
        }

        @media (max-width: 690px) {
          display: block;
          //flex-direction: column;
          margin-top: 10px;
        }


      }

      .level-summary {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        @media (max-width: 690px) {
          margin-top: 0;
          flex-wrap: wrap;
        }

        .level-group {
          display: flex;
          flex-direction: column;
          align-items: center;

          @media (max-width: 690px) {
            //flex-basis: 50%;
            margin: 13px 0;
            flex-basis: 40%;
          }

          @media (max-width: 450px) {
            flex-basis: 33%;
            //margin: 13px 0;
          }

          img {
            width: 140px;
            height: 140px;
            object-position: center;
            @media(max-width: 1100px) {
              width: 90px;
              height: 90px;
            }
          }
          span {
            text-align: center;
            font-size: 14px;

            .level-label {
              white-space: nowrap;
              display: block;
            }

            @media screen and (orientation:portrait) and (max-width: 690px) {
              font-size: 13px;

            }
          }
        }
      }

      .cta {
        margin-top: 18px;
        p {
          text-align: center;
          font-size: 20px;

          @media(max-width: 690px) {
            margin-top: 0px;
            font-size: 15px;
          }
        }
        @media(max-width: 690px) {
          margin-top: 15px;
        }
      }

      .help-button-row {
        display: flex;
        justify-content: center;
        button {
          padding: 10px 15px;
          color: #fff;
          right: 2%;
          bottom: 2%;
          margin: 0 2%;
          text-transform: uppercase;
          font-family: "Alike Angular";
          background-size: 100% auto;
          transition: all 0.2s ease-in-out;
          border-style: solid;
          border-width: 2px 3px;
          border-image: url("../img/border-test-button.png") 5 5 5 5 repeat repeat;
          &:hover {
            background-size: 200% auto;
            background-position: right center;
            cursor: pointer;
            text-decoration: underline;
          }
        }
        .tutorial {
          background-image: linear-gradient(to right, #101113 0%, #424242 51%, #101113 100%);
        }
        .launch-game {
          background-image: linear-gradient(to right, #1011139e 0%, #424242eb 51%, #1011139e 100%);
        }
        //border-image: url(/border-test-button.c381a00a.png) 5 5 5 5 repeat repeat;

      }
    }
  }
}

.scroll-container {
  display: none;
}

  .profile-info {
    display: flex;
    flex-direction: row;
    transition: .2s all ease-in-out;
    margin-left: 55px;
    margin-right: 55px;

    @media (max-width: 1000px) {
      margin-left: 0;
    }

    @media(max-width: 900px) {
      align-items: center;
    }

    @media(max-width: 500px) {
      font-size: 14px;
      margin-right: 4px;
    }

    .character-icon-container {
      width: 56px;
      height: 56px;
      padding: 2px 3px 4px 3px;
      border: 2px solid #fff;
      border-radius: 50px;
      position: relative;

      @media(max-width: 600px) {
        width: 42px;
        height: 42px;
      }

      @media(max-width: 370px) {
        width: 35px;
        height: 35px;
      }

      .character-icon {
        border-radius: 50px;
        background-image: url("../img/knight.png");
        background-size: 180% auto;
        background-position: top center;
        height: 100%;
        width: 100%;
      }
    }

    .game-info {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media(max-width: 370px) {
        margin-left: 10px;
      }
    }

    &:hover {
      opacity: .5;
      cursor: pointer;

      .game-info {
        text-decoration: underline;
      }
    }
  }

  .nav-menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: .75%;
    margin-bottom: 30px;
    justify-content: center;
    position: relative;
    z-index: 2;

    @media(max-width: 500px) {
      width: 98%;
      padding-left: 1%;
      padding-right: 1%;
      margin-bottom: -71px;
      display: inline-block;
    }
    // @media (max-width: 900px) {
    //   justify-content: space-between;
    //   width: 94%;
    // }
  }

  .link-group {

    .link {
      background-image: linear-gradient(to right, #101113 0%, #424242 51%, #101113 100%);
      display: inline-block;
      padding: 10px 15px;
      color: #fff;
      min-width: 100px;
      text-transform: uppercase;
      font-family: "Alike Angular";
      background-size: 100% auto;
      transition: all 0.2s ease-in-out;
      border-style: solid;
      border-width: 2px 3px;
      border-image: url("../img/border-test-button.png") 5 5 5 5 repeat repeat;

      &:hover {
        opacity: .7;
        background-size: 200% auto;
        background-position: right center;
        cursor: pointer;
        text-decoration: underline;
      }

      @media(max-width: 375px) {
        padding: 10px 10px;
        min-width: 64px;
      }
    }

    @media(max-width: 900px) {
      margin-top: 5px;
    }

    a {
      color: #fff;
      text-decoration: none;
    }

    &:hover {
      cursor: pointer;
      opacity: .7;
      background-size: 100% 1px;
    }

  }

  .featured {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .toyota-button {
    position: absolute;
    top: 1%;
    left: 1%;
    z-index: 4;
    @media (max-width: 1000px) {
      top: unset;
      bottom: 1%;
    }
  }

  .help-button {
    position: absolute;
    right: 1%;
    top: 1%;
    z-index: 4;
    @media (max-width: 1000px) {
      top: unset;
      bottom: 1%;
    }
  }

  span {
    text-transform: uppercase;
  }




#play-game {
  padding: 17px 24px;
  color: #fff;
  //max-width: 120px;
  font-size: 20px;
  text-transform: uppercase;
  font-family: 'Alike Angular';
  background-image: linear-gradient(to right, #101113 0%, #424242 51%, #101113 100%);
  background-size: 100% auto;
  box-shadow: 0 0 20px #eeeeee6b;
  transition: all 0.2s ease-in-out;
  border-style: solid;
  border-width: 2px 3px;
  border-image: url("../img/border-test-button.png") 5 5 5 5 repeat repeat;

  a {
    text-decoration: none;
    color: #fff;
  }

  &:hover {
    opacity: .7;
    //width: 65%;
    background-size: 200% auto;
    background-position: right center;
    box-shadow: 0 0 30px #eeeeee6b;
    cursor: pointer;
    z-index: 1;
    text-decoration: underline;
  }

  @media(max-width: 500px) {
    padding: 10px 12px;
    font-size: 15px;
  }
}

.video-container.active, .intro-video-container.active {
  background-image: radial-gradient(63.62% 74.88% at 50% 50%, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.9) 100%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media(max-width: 600px) {
    background-image: radial-gradient(63.62% 74.88% at 50% 50%, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.9) 100%);
  }

  //phone horizontal
  @media(max-height: 600px) and (max-width: 900px) and (orientation: landscape) {
    background-image: unset;
    background-color: #000;
  }

  .preserve-dims {
    width: 90vw;
    max-width: 1000px;
    max-height: 562.5px;
    aspect-ratio: 16 / 9;
    position: relative;

    @media (max-height: 610px) and (min-aspect-ratio: 16 / 9) and (orientation: landscape) {
      height: 90vh;
      width: auto;
    }

    @media(max-height: 600px) and (max-width: 900px) and (orientation: landscape) {
      height: 72%;
    }

    &:before {
      content: '';
      position: absolute;
      top: -7px;
      right: 100%;
      width: 29px;
      height: calc(100% + 14px);
      background-image: url('../img/video-border-left.webp');
      background-size: 100% 100%;
    }

    &:after {
      content: '';
      position: absolute;
      top: -7px;
      left: 100%;
      width: 29px;
      height: calc(100% + 14px);
      background-image: url('../img/video-border-right.webp');
      background-size: 100% 100%;
    }

    #border-top {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 7px;
      background-image: url('../img/video-border-top.webp');
      background-size: 100% 100%;
    }

    #border-bottom {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 7px;
      background-image: url('../img/video-border-bottom.webp');
      background-size: 100% 100%;
    }

    #video {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      background-size: contain;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    video {
      width: 100%;
      height: 100%;
      vertical-align: center;
    }
  }

}

.video-button.active {
  button {
    color: #fff;
    max-width: 200px;
    padding: 10px 20px;
    margin: 0 20px;
    font-family: 'Alike Angular', serif;
    text-transform: uppercase;
    opacity: 1;
    height: 45px;
    background-image: linear-gradient(to right, #101113 0%, #424242 51%, #101113 100%);
    background-size: 100% auto;
    box-shadow: 0 0 20px #eeeeee6b;
    transition: all 0.2s ease-in-out;
    border-style: solid;
    border-width: 2px 3px;
    border-image: url("../img/border-test-button.png") 5 5 5 5 repeat repeat;
    @media(max-width: 600px) {
      background-color: #070809;
    }


  }
}

.video-button {
  margin-top: -56px;
  height: 65px;
  margin-bottom: 26px;
  button {
    border: 0;
    transition: all .5s ease-in-out;
    opacity: 0;
    height: 0;
    &:hover {
      cursor: pointer;
      opacity: .7;
      //width: 65%;
      background-size: 200% auto;
      background-position: right center;
      text-decoration: underline;
    }
  }
  @media(max-height: 600px) and (max-width: 900px) and (orientation: landscape){
    margin-top: 0;
    margin-bottom: 20px;
    height: 37px;
  }
}

.video-container, .intro-video-container {
  display: none;
}

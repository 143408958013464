.profile.active {
      z-index: 1000;
  .scroll {
    padding: 105px 80px 90px 105px;
    @media(max-height: 600px) {
      padding: 50px 80px;
    }
    @media(max-width: 690px) {
      padding: 30px 42px;
    }
    .content {
      justify-content: center;

      @media (max-width: 690px) {
        padding-right: 0;
        display: flex;
      }

      @media (max-width: 500px) {
        //top: 6%;
        left: 0%;
        right: 0%;
        padding-top: 0%;
        height: 100%;
        position: relative;
        overflow-y: auto;
        width: 100%;
      }

        .close-profile-button {
          position: absolute;
          right: 0%;
          top: 0%;
          z-index: 2;

          @media (max-width: 690px) {
            top: 0%;
            //right: 10%;
          }

          img {
            width: 30px;
            height: 30px;
            transition: all 0.2s ease-in-out;
            opacity: 1;

            &:hover {
              opacity: .5;
              width: 32px;
              height: 32px;
              cursor: pointer;
              z-index: 1;
            }

          }
        }

        .profile-flex-group {
          display: flex;
          flex-direction: row;
          height: 100%;
          width: 100%;
          position: relative;

          @media (max-width: 690px) {
            display: block;
            height: unset;
          }

          .knight-group {
            display: flex;
            flex-direction: column-reverse;
            width: 30%;

            @media (max-width: 690px) {
              display: block;
              float: left;
              margin-left: 25px;
              margin-right: 34px;
              margin-top: 55px;
            }

            @media (max-width: 500px) {
              margin-right: 29px;
              width: 33%;
              margin-left: 0;
            }

            .user-name {
              text-transform: uppercase;
              font-size: 30px;
              line-height: 52px;
              text-align: center;
              @media (max-width: 690px) {
                top: 0;
                position: absolute;
              }
              @media (max-width: 500px) {
                // margin-top: -49px;
                // margin-bottom: -5px;
                font-size: 24px;
              }

            }

            .knight-img-group {
              display: flex;
              flex-direction:column;
              border: 2px solid;
              flex-grow: 1;
              padding: 20px;

              @media (max-width: 690px) {
                height: 224px;
                padding: 16px;
                margin-left: 7px;
              }

              @media (max-width: 500px) {
                padding: 13px;
              }

              .profile-knight {
                background-image: url("../img/knight.png");
                background-size: contain;
                background-position: center;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                mix-blend-mode: multiply;
              }
            }
          }

          .knight-stats {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            flex: auto;
            padding: 3% 7% 7% 4%;

            @media (max-width: 1000px) {
              padding-left: 45px;
            }

            @media (max-width: 690px) {
              //padding: 3% 13% 7% 0%;
              padding-left: 34px;
              display: block;
            }

            @media (max-width: 500px) {
              padding: 0;
              //padding: 3% 7% 2% 4%;
            }

            .profile-level-box {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              //flex: 1 0 50%;
              order: 1;
              flex-basis: 45%;
              border: 2px solid #432424;
              position: relative;
              margin-bottom: 10%;

              @media (max-width: 690px) {
                height: 104px;
                margin: 45px 32px;
              }

              @media (max-width: 500px) {
                //height: 50px;
                //margin-top: 50px;
                margin: 55px 32px 21px;
              }

              .level-title {
                top: -3px;
                height: 4px;
                position: absolute;
                display: block;
                background-color: #F6D9AA;
                font-size: 35px;
                width: 65%;
                @media(max-width: 1000px) {
                  font-size: 24px;
                  width: 50%;
                }
                @media (max-width: 500px) {
                  font-size: 19px;
                  width: 60%;
                }
                span {
                  width: 100%;
                  text-align: center;
                  top: -20px;
                  position: absolute;
                  @media(max-width: 1000px) {
                    top: -15px;
                  }
                  @media (max-width: 500px) {
                    top: -12px;
                  }
                }
              }

              .profile-level-num {
                font-size: 80px;
                line-height: 0;
                @media(max-width: 1000px) {
                  font-size: 55px;
                }
                @media (max-width: 500px) {
                  font-size: 37px;
                }
              }
            }
            .score-box {
              order: 2;
              flex-basis: 45%;
              border: 2px solid #432424;
              margin-bottom: 10%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              position: relative;

              @media (max-width: 690px) {
                height: 104px;
                margin: 34px;
              }



              .score-title {
                top: -3px;
                height: 4px;
                position: absolute;
                display: block;
                background-image: linear-gradient(to right, #f4d3a0, #eec68d);
                font-size: 35px;
                width: 65%;
                @media(max-width: 1000px) {
                  font-size: 24px;
                  width: 50%;
                }
                @media(max-width: 690px) {
                  background-image: linear-gradient(to right, #fce7bf, #fce7bf);
                }
                @media (max-width: 500px) {
                  background-image: unset;
                  background-color: #fde9c1;
                  top: -3px;
                  font-size: 19px;
                  width: 60%;
                }

                span {
                  width: 100%;
                  text-align: center;
                  top: -20px;
                  position: absolute;
                  @media(max-width: 1000px) {
                    top: -15px;
                  }
                  @media (max-width: 500px) {
                    top: -12px;
                  }
                }
              }

              .your-points {
                font-size: 70px;
                line-height: 0;
                @media(max-width: 1000px) {
                  font-size: 55px;
                }
                @media (max-width: 500px) {
                  font-size: 37px;
                }
              }
            }

            .tokens-gathered {
              order: 3;
              flex-basis: 100%;
              border: 2px solid #432424;
              padding: 2%;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;

              @media (max-width: 690px) {
                height: 104px;
                margin-top: 50px;
                width: 95%;
                margin-left: 8px;
              }

              @media (max-width: 500px) {
                width: 88%;
                height: unset;
                margin: auto;
                padding-top: 22px;
                padding-bottom: 26px;
                margin-left: 0;
                margin-right: 0;
              }

              .tokens-title {
                top: -2px;
                height: 3px;
                position: absolute;
                display: block;
                background-color: #fce5bc;
                font-size: 35px;
                width: 78%;

                @media(max-width: 1000px) {
                  font-size: 24px;
                  top: -2px;
                  width: 65%;
                }
                @media (max-width: 500px) {
                  font-size: 20px;
                  top: -3px;
                  width: 65%;
                  height: 4px;
                }

                  @media (max-width: 395px) {
                  font-size: 16px;
                  line-height: 20px;
                  top: 2px;
                  z-index: 10;

                  &:after {
                    content: '';
                    position: absolute;
                    top: -10px;
                    left: 0;
                    width: 100%;
                    height: 20px;
                    background: #fce5bc;
                    z-index: -1;
                  }
                }
                span {
                  width: 100%;
                  text-align: center;
                  top: -20px;
                  position: absolute;
                  @media(max-width: 1000px) {
                    top: -15px;
                  }
                  @media (max-width: 500px) {
                    top: -14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .tokens-group {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: -48px;
    margin-bottom: -60px;
    justify-content: space-around;

    @media(max-width: 1000px) {
      margin-top: -60px;
    }

    @media (max-width: 500px) {
      margin-top: 0;
      margin-bottom: 0;
      padding: 6px;
      flex-wrap: wrap;
    }


    .token {
      background-size: contain;
      background-repeat: no-repeat;
      flex-basis: 18%;
      height: 100px;
      background-position: center;

      @media(max-width: 1000px) {
        flex-basis: 15%;
        height: 60px;
      }
      @media (max-width: 500px) {
        flex-basis: unset;
        height: 57px;
        width: 59px;
        margin: 2px 2px;
      }
      @media(max-width: 415px) {
        height: 38px;
        width: 41px;
      }
    }

    .token-1 {
      background-image: url("../img/tokens/level-1-lock.png");
    }
    .token-1.unlocked {
      background-image: url("../img/tokens/level-1-gold.png");
    }

    .token-2 {
      background-image: url("../img/tokens/level-2-lock.png");
    }
    .token-2.unlocked {
      background-image: url("../img/tokens/level-2-gold.png");
    }

    .token-3 {
      background-image: url("../img/tokens/level-3-lock.png");
    }
    .token-3.unlocked {
      background-image: url("../img/tokens/level-3-gold.png");
    }

    .token-4 {
      background-image: url("../img/tokens/level-4-lock.png");
    }
    .token-4.unlocked {
      background-image: url("../img/tokens/level-4-gold.png");
    }

    .token-5 {
      background-image: url("../img/tokens/level-5-lock.png");
    }
    .token-5.unlocked {
      background-image: url("../img/tokens/level-5-gold.png");
    }
  }

.profile {
  display: none;
}

.toyota.active {
  z-index: 100;
  .scroll {
    .content {
      .close-toyota-button {
        position: absolute;
        right: 0%;
        top: 0%;
        img {
          width: 30px;
          height: 30px;
          opacity: 1;
          transition: all 0.2s ease-in-out;

          &:hover {
            opacity: .5;
            width: 32px;
            height: 32px;
            cursor: pointer;
            z-index: 1;
          }
        }
      }
    }
  }
  .toyota-splash {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90%;
    @media(max-width: 700px) {
      flex-direction: column;

    }

    .toyota-info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 30%;
      padding: 0px 12%;
      // border-right: solid 2px #432424;
      justify-content: center;
      img {
        width: 100%;
        @media(max-width: 700px) {
          width: 105px;
          //height: auto;
          //min-width: 100px;
          margin: auto;
          margin-bottom: 30px;
        }
        @media(max-width: 500px) {
          width: 55px;
          margin-bottom: 10px;
        }
      }
    }

    .toyota-text {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-right: 10%;
      // padding-left: 6%;
      justify-content: center;
      margin-top: -10%;

      @media(max-width: 700px) {
        width: 80%;
        padding-right: 0;
        padding-left: 0;
        margin: auto;
        margin-top: 0;
      }

      h1 {
        margin-bottom: .25em;
      }

      p:first-of-type {
        margin-top: .25em;
      }

      button {
        margin-top: .5em;
        background-color: transparent;
        font-family: 'Alike Angular', serif;
        padding: 10px 20px;
        max-width: 300px;
        font-size: 20px;
        align-self: center;
        border: solid 2px #432424;
        transition: all 0.2s ease-in-out;
        a {
          color: #432424;
          text-decoration: none;
        }

        &:hover {
          opacity: .5;
          //width: 65%;
          cursor: pointer;
          z-index: 1;
        }
      }
    }
  }
}

.toyota {
  display: none;
}

@import 'nav.scss';
@import 'map.scss';
@import 'help.scss';
@import 'levels.scss';
@import 'video.scss';
@import 'profile.scss';
@import 'back-to-toyota.scss';
@import 'login-page.scss';

body {
  background-color: #000000;
  color: #ffffff;
  font-family: 'Alike Angular', serif;
}

.background-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;

  .texture {
    z-index: -4;
    position: relative;
    background-image: url("../img/background-texture.webp");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  .map-lines {
    z-index: -2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/map-lines.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  .vignette {
    z-index: -1;
    background-image: radial-gradient(63.62% 74.88% at 50% 50%, rgba(255, 255, 255, .1) 0%, rgba(0, 0, 0, 0.8) 100%);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
  }
}

.game-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
}

#screenshot-canvas, #paper-texture {
  display: none;
}

//stylings for the level modals
.level.active {
  .scroll {
    display: flex;
    padding: 105px 80px;
    @media(max-width: 800px) {
      padding: 80px 60px;
    }
    //if somebody flips their phone
    @media(max-height: 600px) {
      padding: 50px 80px;
    }
    @media(max-width: 690px) {
      padding: 46px 55px;
    }

    .content {
      @media (max-width: 690px) {
        padding-right: 0;
      }
      .close-level-button {
        position: absolute;
        right: 2%;
        top: 0%;

        @media(max-width: 1100px) {
          right: 0%;
        }

        @media  (max-width: 690px) {
          top: 0%;
          //right: 9%;
        }

        @media (max-width: 400px) {
          right: 0;
          top: 2%;
        }

        img {
          width: 30px;
          height: 30px;
          opacity: 1;
          transition: all 0.2s ease-in-out;

          &:hover {
            opacity: .5;
            width: 32px;
            height: 32px;
            cursor: pointer;
            z-index: 1;
          }
        }
      }
    }
  }
  .level-splash {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90%;

    @media (max-width: 690px) {
      display: flex;
      flex-direction: column;
      // align-items: center;
      height: 100%;
      justify-content: center;
    }

    .level-info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      max-width: 50%;
      border-right: solid 3px #432424;
      justify-content: center;
      align-items: center;

      @media (max-width: 690px) {
        border-right: 0;
        max-width: 100%;
        align-items: center;
      }

      @media (max-width: 400px) {
        flex-grow: unset;
      }

      .level-box {
        width: 100px;
        border: 3px solid #432424;
        height: 75px;
        position: absolute;
        top: 35px;
        left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: 690px) {
          left: 0%;
          top: 13px;
        }

        @media (max-width: 400px) {
          left: 5%;
          top: 13px;
          width: 80px;
          height: 65px
        }

        @media(max-height: 600px) {
          top: 10px;
          left: 0;
        }

        .title {
          top: -10px;
          position: absolute;
          display: block;
          background-color: #E4BC86;
          padding-left: 5px;
          padding-right: 5px;
        }

        .level-num {
          font-size: 42px;
          @media (max-width: 400px) {
            font-size: 34px;
          }
        }
      }

      .island {
        max-width: 90%;
        @media(max-height: 600px) {
          max-width: 60%;
        }
      }

      .island img {
        max-width: 95%;
        height: auto;

        @media (max-width: 690px) {
          width: 200px;
          max-width: 200px;
          margin-top: 120px;
        }

        @media (max-width: 400px) {
          width: 170px;
        }

      }
    }

    .level-text {
      display: flex;
      flex-direction: column;
      //max-width: 50%;
      width: 100%;
      padding-left: 4%;
      justify-content: center;


      @media(max-width: 1100px) {
        padding-right: 7px;
      }

      @media (max-width: 690px) {
        padding-left: 0;
        padding-right: 0;
      }

      @media (max-width: 400px) {
        margin-top: 7%;
      }

      h1 {
        margin-bottom: .25em;
      }

      p:first-of-type {
        margin-top: .25em;
      }

      button {
        margin-top: 10px;
        background-color: transparent;
        font-family: 'Alike Angular', serif;
        padding: 10px 20px;
        max-width: 200px;
        font-size: 20px;
        align-self: center;
        transition: all 0.2s ease-in-out;
        border: 2px solid #432424;
        color: #432424;

        &:hover {
          opacity: .5;
          //width: 65%;
          cursor: pointer;
          z-index: 1;
        }

        //remove flexbox styles trying to fix android issues
        @media(max-width: 425px) {
          margin: auto;
          margin-top: 10px;
          margin-bottom: 10px;
          align-self: unset;
          display: block;
          min-height: 49px;
        }

      }
      #play-video {
        display: none;
      }
      #play-video.active {
        display: block;
      }
      #back-to-quorum {
        display: none;
        a {
          text-decoration: none;
          color: #432424;
        }
      }
      #back-to-quorum.active {
        max-width: 250px;
        display: block;
      }
      #back-to-map {
        display: none;
      }
      #back-to-map.active {
        display: block;
      }
    }
  }
}

.ending-level-container.active {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;

  .ending-screen-close-button {
    z-index: 3;
    position: absolute;
    right: 2%;
    top: 2%;
    width: 175px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #ebebeb;

    @media (max-width: 600px) {
      right: 5%;
      top: 2%;
      width: 155px;
    }

    .back-text {
      text-transform: uppercase;
      white-space: nowrap;
      opacity: 1;
      transition: all .2s ease-in-out;
      color: #ebebeb;
      &:hover {
        text-decoration: underline;
        opacity: .5;
        width: 50px;
        cursor: pointer;
      }
    }

    img {
      width: 35px;
      height: 35px;
      opacity: 1;
      transition: all .2s ease-in-out;
      &:hover {
        opacity: .5;
        margin-bottom: -2px;
        margin-left: -2px;
        width: 37px;
        height: 37px;
        cursor: pointer;
      }

      @media(max-width: 690px) {
        width: 35px;
      }

    }
  }

  .ending-background {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background-image: radial-gradient(63.62% 74.88% at 50% 50%, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.9) 100%);
  }

  .ending-wrapper {
    width: 1152px;
    height: 648px;
    position: relative;
    border-style: solid;
        /* border-radius: 30px; */
    border-image-width: 157px 157px;
    border-width: 33px 175px 33px 175px;
    border-image: url("../img/border-test-2.png") 142 154 160 141 stretch stretch;

    @media(max-width: 1000px) {
      width: 85%;
      height: 84%;
      border-width: 33px 70px 33px 71px;
    }

    @media(max-width: 780px) {
      border-width: 33px 50px 33px 50px;
    }



  }

  .ending-knight {
    width: 36%;
    bottom: 0;
    position: absolute;
    height: 95%;
    z-index: 0;
    background-image: url("../img/knight-big.png");
    background-repeat: no-repeat;
    background-size: cover;
    @media(max-width: 1000px) {
      background-size: contain;
      background-position: bottom;
    }
    @media(max-width: 600px) {
      display: none;
    }
  }

  .ending-text-group {
    width: 96%;
    padding: 2%;
    position: absolute;
    bottom: 0;
    height: 0%;
    background-color: rgba(0, 0, 0, .8);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    opacity: 0;
    animation-name: animateText;
    animation-delay: 0s;
    animation-duration: .5s;
    animation-fill-mode: forwards;

    @media(max-width: 690px) {
      height: 39%;
    }

    @media(max-width: 600px) {
      height: 98%;
      padding: 1% 2%;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }

    .ending-text {
      font-size: 31px;
      padding-right: 50px;
      padding-left: 30px;
      display: flex;
      margin-top: -27px;
      align-items: center;
      color: #ebebeb;
      @media (max-width: 1200px) {
        font-size: 21px
      }
      @media(max-width: 690px) {
        font-size: 18px;
        padding-right: 40px;
      }
      @media(max-width: 600px) {
        font-size: 18px;
        padding-left: 9px;
        padding-right: 9px;
      }

    }

    .ending-img {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 200px;


      @media (max-width: 1000px) {
        justify-content: center;
        width: 177px;
        margin-top: -34px;
        padding-right: 20px;
      }

      @media(max-width: 690px) {
        justify-content: center;
        margin-top: -23px;
        margin-right: 15px;
      }

      @media(max-width: 600px) {
        justify-content: center;
        margin-top: 0;
        margin-right: 0;
        width: 165px;
        padding-right: 0;
        margin-bottom: 42px;
      }

      .ending-badge {
        width: 0;
        opacity: 0;
        height: auto;
        animation-name: grow;
        animation-duration: .5s;
        animation-delay: .25s;
        animation-fill-mode: forwards;
        // @media(max-width: 690px) {
        //   width: 74%;
        // }
      }


      .new-item {
        margin-top: 0px;
        width: 200px;
        height: auto;
        opacity: 0;
        animation-name: fadeInItem;
        animation-delay: .25s;
        animation-duration: .5s;
        animation-fill-mode: forwards;
        @media (max-width: 1000px) {
          width: 175px;
        }
        @media(max-width: 690px) {
          margin-top: -30px;
          width: 152px;
        }
      }
    }
  }
}

@keyframes grow {
  from { width: 0; opacity: 0 }
  to { width: 90%; opacity: 1}
}
@keyframes animateText {
  from { opacity: 0; height: 0}
  to { opacity: 1; height: 30%; }
}
@keyframes fadeInItem {
  from { opacity: 0; margin-top: 0}
  to { opacity: 1; margin-top: -50px; }
}


@media(max-width: 690px) {
  @keyframes animateText {
    from { opacity: 0; height: 0}
    to { opacity: 1; height: 39%; }
  }
  // @keyframes grow {
  //   from { width: 0; opacity: 0}
  //   to { width: 74%; opacity: 1}
  // }
  @keyframes fadeInItem {
    from { opacity: 0; margin-top: 0}
    to { opacity: 1; margin-top: -30px; }
  }
}

@media(max-width: 600px) {
  @keyframes animateText {
    from { opacity: 0; height: 0}
    to { opacity: 1; height: 98%; }
  }
}


#ending-5.ending-level-container.active {

  @keyframes animateTextFull {
    from { opacity: 0; height: 0 }
    to { opacity: 1; height: 100%; }
  }

  @keyframes growPartly {
    from { width: 0; opacity: 0 }
    to { width: 80%; opacity: 1}
  }

  .ending-wrapper {
    border-width: 27px 25px 33px 25px;
    @media(max-width: 450px) {
      height: 92%;
      overflow-y: auto;
    }
  }

  .ending-text-group {
    padding: 0;
    width: 100%;
    //height: 100%;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
    animation-name: animateTextFull;
    animation-delay: 0s;
    animation-duration: .5s;
    animation-fill-mode: forwards;

    @media(max-width: 600px) {
      justify-content: center;
    }

    .ending-img {

      @media(max-width: 450px) {
        margin-top: 35px;
        width: 124px;
      }

      .ending-badge {
        animation-name: growPartly;
        animation-duration: 0.5s;
        animation-delay: 0.25s;
        animation-fill-mode: forwards;
      }

    }

    .new-item {
      @media(max-width: 450px) {
        width: 144px;
      }
    }



    .ending-text {
      font-size: 21px;
      @media(max-width: 600px) {
        font-size: 17px;
      }
      @media(max-width: 450px) {
        font-size: 16px;
      }
    }
  }

}

.ending-level-container {
  display: none;
}

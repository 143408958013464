.map-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  position: relative;

  .map {
    margin: auto;
    max-width: 75%;

    .click-here {
      pointer-events: none;
    }
    .click-here-img {
      opacity: 0;
      transition: 1.4s all ease-in-out;
    }

    .click-here-img.active {
      opacity: 1;
      animation-name: bounce;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-delay: 1.4s;
    }

    @keyframes bounce {
      0%, 100% {transform: translateY(0)}
      50% {transform: translateY(-20px);}
    }

    #desktop-map {
      display: none;

      @media (min-width: 601px) {
        display: block;
      }
    }

    #mobile-map {
      display: none;

      @media (max-width: 600px) {
        display: block;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;

      #axe-island,
      #bow-island,
      #dragon-island,
      #sword-island,
      #shield-island {
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }
      }

      #walking-path {
        pointer-events: none;
        path {
          fill: white;
        }
      }

      .map-icons-disabled, .map-icons-enabled, .knight-icon {
        pointer-events: none;
      }
    }
  }

  .map-row-1 {
    max-width: 90%;
    margin-left: 10%;
    height: 48%;
    display: flex;
    flex-grow: 2;
    flex-direction: row-reverse;
  }

  .map-row-2 {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    padding-top: 1%;
    height: 52%;
  }

  .island-container {
    display: flex;
    flex-grow: 1;
    position: relative;
    opacity: 0.4;
    z-index: 0;
    transition: all 0.4s ease-in-out;

    .island-position {

      .coin-group {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .coin {
          width: 50px;
          height: 50px;
          background-size: 50px auto;
          background-repeat: no-repeat;

          @media(max-width: 800px) {
            width: 40px;
            height: 40px;
            background-size: 40px auto;
          }

          @media(max-width: 600px) {
            width: 30px;
            height: 30px;
            background-size: 30px auto;
          }
        }
      }

      //this should only show when island-container has a current class on it
      .knight-position {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        img.knight-img {
          weight: 60px !important;
          height: 0px;
          transition: .2s all ease-in-out;
        }
      }

      img {
        max-width: 100%;
        height: auto;
        object-fit: contain;
        filter: grayscale(10%);
      }
    }

    @media (max-width: 600px) {
      min-height: 20%;
      height: auto;
      width: 100%;
      display: inline-block;
    }
    &:hover {
      cursor: pointer;
      opacity: 1;
      z-index: 1;
    }
  }

  //only our current level should show knight
  .island-container.current {
    .island-position {
      .knight-position {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        img.knight-img {
          width: 60px !important;
          height: 140px;
          margin-left: -20px;


          @media(max-width: 800px) {
            width: 45px !important;
            margin-left: -15%;
          }
        }
      }
    }
  }



  .island-container.unlocked {
    z-index: 1;
    opacity: 1;

    .island-position {

      img {
        filter: grayscale(0%);
      }

    }
  }

  #level-1 {
    .island-position {
      position: absolute;
      width: 100%;
      height: auto;
      top: 1%;

      img {
        max-width: 110%;
        width: 109%;
      }

      .coin-group {
        .coin {
          margin-left: -55px;
          background-image: url("../img/tokens/level-1-lock.png");
        }
      }
    }
  }

  #level-1.unlocked.won {
    .island-position {
      .coin-group {
        .coin {
          background-image: url("../img/tokens/level-1-gold.png");
        }
      }
    }
  }

  #level-2 {
    .island-position {
      position: absolute;
      width: 100%;
      height: 100%;
      top: -23%;
      left: -12%;

      .coin-group {
        .coin {
          top: 45%;
          right: 25%;
          position: absolute;
          // margin-left: 18%;
          // margin-top: 25%;
          // margin-right: -40%;
          // margin-top: -4%;
          background-image: url("../img/tokens/level-2-lock.png");

          @media(max-width: 1000px) {
            top: 35%;
          }

          @media(max-width: 800px) {
            top: 25%;
          }

          @media(max-width: 600px) {
            top: 50%;
            right: 30%;
          }

        }
      }

      @media(max-width: 900px) {
        top: -13%;
      }
    }
  }

  #level-2.unlocked.won {
    .island-position {
      .coin-group {
        .coin {
          background-image: url("../img/tokens/level-2-gold.png");
        }
      }
    }
  }

  #level-3 {
    .island-position {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 80%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-position: left bottom;
      }

      .coin-group {
        .coin {
          margin-left: 5%;
          background-image: url("../img/tokens/level-3-lock.png");

          @media(min-width: 1700px) {
            margin-left: -5%;
          }

          @media(max-width: 600px) {
            margin-left: 20%;
          }
        }
      }
    }
  }

  #level-3.unlocked.won {
    .island-position {
      .coin-group {
        .coin {
          background-image: url("../img/tokens/level-2-gold.png");
        }
      }
    }
  }

  #level-4 {
    .island-position {
      right: 0;
      bottom: 0;
      width: 100%;
      position: absolute;
      height: auto;

      img {
        width: 100%;
        height: 100%;
        object-position: right bottom;
      }

      .coin-group {
        .coin {
          left: 60%;
          top: 50%;
          position: absolute;
          // margin-left: 12%;
          background-image: url("../img/tokens/level-4-lock.png");

          // @media(max-width: 800px) {
          //   margin-left: 18%;
          //   margin-top: 15%;
          // }

          // @media(max-width: 600px) {
          //   margin-top: 0%;
          // }
        }
      }
    }
  }

  #level-4.unlocked.won {

    .island-position {

      .coin-group {
        .coin {
          background-image: url("../img/tokens/level-4-gold.png");
        }
      }
    }
  }

  #level-5 {
    .island-position {
      // right: 0;
      // bottom: 0;
      //height: 100%;
      height: auto;
      width: 100%;
      position: absolute;

      .coin-group {
        .coin {
          background-image: url("../img/tokens/level-5-lock.png");
        }
      }
    }
  }

  #level-2.unlocked.won {
    .island-position {

      .coin-group {
        .coin {
          background-image: url("../img/tokens/level-5-gold.png");
        }
      }
    }
  }
}

@media (min-width: 1700px) {
  .map-container {
    padding-top: 12px;

    .map {
      padding-top: 1%;
      padding-bottom: 3%;
      height: 89%;

        #level-1 {
            .island-position {
              width: 110%;
              height: 110%;
              top: -2%;

                img {
                  width: 100%;
                  height: 100%;
                }
            }
        }

        #level-2 {
            .island-position {
              width: 100%;
              height: 100%;
              top: -15%;
              left: -12%;

                img {
                  width: 100%;
                  height: 110%;
                }
            }
        }
        #level-4, #level-3 {
            .island-position {
              height: 100%;
            }
        }

        #level-5 {
            .island-position {
              width: 100%;
              height: 100%;

                img {
                  width: 100%;
                  height: 100%;
                }
            }
        }
    }
  }
}



@media (max-width: 600px) {
  .map-container {

      .map {
        max-width: 90%;
        flex-direction: row;
        flex-wrap: wrap;

        #level-1 {
          order: 1;

          .island-position {
            height: 100%;
            top: -15%;
            left: 5%;

            img {
              height: 110%;
              width: 110%;
              max-width: 100%;
            }
          }
        }

        #level-2 {
          order: 2;
          width: 50%;

          .island-position {
            height: 100%;
            top: -20%;
            left: -11%;

            img {
              height: 113%;
              width: 100%;
            }
          }
        }

        #level-3 {
          order: 2;
          width: 49%;

          .island-position {
            height: 100%;
            width: 90%;

            img {
              height: 115%;
              width: 110%;
              object-position: right bottom;
            }
          }
        }

        #level-4 {
          order: 3;

          .island-position {
            height: 95%;

            img {
              object-position: center;
            }
          }
        }

        #level-5 {
          order: 4;

          .island-position {
            height: 100%;
            top: 5%;
            left: -7%;

            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
}
